import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Popover,
  Tabs,
  Tab,
  Grid,
  Chip,
  Fab,
  useScrollTrigger,
  Fade,
} from "@mui/material";

import classes from "./home.module.css";
import classesOne from "../property/property.module.css";
import Banner from "../../Assets/Images/banner.png";
import LocationPin from "../../Assets/Images/pins.png";
import BuildingOne from "../../Assets/Images/building-1.png";
import BuildingTwo from "../../Assets/Images/building-2.png";
import BuildingThree from "../../Assets/Images/building-3.png";
import FindProperty from "../../Assets/Images/find-property.png";
import Asset from "../../Assets/Images/asset.png";
import Help from "../../Assets/Images/help.png";
import PropertyOne from "../../Assets/Images/property-1.png";
import PropertyTwo from "../../Assets/Images/property-2.png";
import PropertyThree from "../../Assets/Images/property-3.png";
import PropertyFour from "../../Assets/Images/property-4.png";
import LandOne from "../../Assets/Images/land-1.png";
import LandTwo from "../../Assets/Images/land-2.png";
import LockKey from "../../Assets/Images/lock-key.png";
import PhoneInHand from "../../Assets/Images/phone-in-hand.png";
import {
  ContactIcon,
  CallNowIcon,
  SearchIcon,
  ServiceFiveIcon,
  ServiceFourIcon,
  ServiceOneIcon,
  ServiceSixIcon,
  ServiceThreeIcon,
  ServiceTwoIcon,
  WhatsAppIcon,
  WhiteDownArrowIcon,
  ChatIcon,
  LockKeyIcon,
  PlanCheckIcon,
  PlanUnCheckIcon,
  WhatsappIcon,
} from "../../Assets/Icons/icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./home.css";
import { FaChevronUp } from "react-icons/fa";
import PropertySlider from "./PropertySlider";
import FooterSection from "./FooterSection";
import { useDispatch, useSelector } from "react-redux";
import {
  addWishlist,
  allServiceList,
  allUserProperties,
  clearPropertyData,
  deleteProperty,
  getAllProperties,
  getAllValues,
  getHomePageProperties,
  savePropertyCount,
  singleProperty,
  updatePropertyStatus,
} from "../../Redux/Actions/userActions";
import { Link, useNavigate } from "react-router-dom";
import {
  compareDatesWithin24Hours,
  countDaysFromDateToNow,
  formatNumber,
  getDecryptedCookie,
} from "../../Utilities/commonFunctions";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { StyledInput } from "../../Components/commonInputFeild";
import { PiSuitcaseSimpleDuotone } from "react-icons/pi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import Plans from "./Plans";
import DeleteConfirmation from "../popup/DeleteConfirmation";
import ContactPop from "../popup/ContactPop";
import {
  LoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
  Autocomplete as GoogleAutocomplete,
} from "@react-google-maps/api";
import FeedbackPop from "../popup/FeedbackPop";
import ReviewPop from "../popup/ReviewPop";
import ContactOwnerPop from "../popup/ContactOwnerPop";
import Dropdown from "./Dropdown";
import { ERROR_ALERT } from "../../Redux/Constants/userConstants";
import AlertBox from "../../Components/AlertBox";
import GuestUserPop from "../popup/GuestUserPop";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 850 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 850, min: 0 },
    items: 1,
  },
};

let test_coordinates = [
  {
    lat: 11.114420815649309,
    lng: 77.29753523729987,
  },
  {
    lat: 11.167496663823886,
    lng: 77.35484354645698,
  },
  {
    lat: 11.103180617473994,
    lng: 77.40531838631077,
  },
];

const customIcon1 = {
  url: LocationPin,
  // scaledSize: new window.google.maps.Size(50, 50),
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

const HomePage = () => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const [searchCoordinates, setSearchCoordinates] = useState(null);
  const [value, setValue] = useState("one");
  const [locationValue, setLocationValue] = useState("one");
  const [isScroll, setIsScroll] = useState(false);
  const [demandProperties, setDemandProperties] = useState([
    {
      name: "Orchid Salisbury",
      location: "Near temple street, Coimbatore",
      price: "3.5",
      feet: "1500",
      photos: [{ image: BuildingOne }],
      highlights: ["Big Parking", "Playground", "Open Balcony"],
    },
    {
      name: "Orchid Salisbury",
      location: "Near Bank, Coimbatore",
      price: "11.3",
      feet: "3300",
      photos: [{ image: BuildingTwo }],
      highlights: [
        "Nearby Market",
        "Nearby School",
        "Big Parking",
        "Playground",
        "Open Balcony",
      ],
    },
    {
      name: "Orchid Salisbury",
      location: "Near temple street, Coimbatore",
      price: "9.25",
      feet: "2700",
      photos: [{ image: BuildingThree }],
      highlights: ["Big Parking", "Playground"],
    },
    {
      name: "Orchid Salisbury",
      location: "Near temple street, Coimbatore",
      price: "3.5",
      feet: "1500",
      photos: [{ image: BuildingOne }],
      highlights: ["Big Parking", "Playground", "Open Balcony"],
    },
  ]);
  const [asset, setAsset] = useState([{ img: Asset }]);
  const [deletePop, setDeletePop] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [homeProperties, setHomeProperties] = useState({});
  const [homePropertyFilter, setHomePropertyFilter] = useState("-1");
  const [homePropertyFilterId, setHomePropertyFilterId] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [myProperties, setMyProperties] = useState({
    properties: [],
    TotalCount: "",
  });
  const [heart, setHeart] = useState(false);
  const [contactPopup, setContactPopup] = useState(false);
  const [isDrop, setIsDrop] = useState(false);
  const [contactPopupDetail, setContactPopupDetails] = useState({
    phone: "",
    email: "",
    name: "",
  });
  const [sorting, setSortings] = useState({ listing: "asc", page: 1 });
  const [propertyFilter, setPropertyFilter] = useState("-1");
  const [userValues, setUserValues] = useState({});
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [centerPoint, setCenterPoint] = useState({
    lat: 11.103180617473994,
    lng: 77.40531838631077,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allProperties, ln, servicesList } = useSelector(
    (state) => state.userLogin
  );
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
  let user_id = JSON.parse(localStorage.getItem("userToken"))?.id;
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });
  const [userCoordinates, setUserCoordinates] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [loginErr, setLoginErr] = useState("");
  const [getContact, setGetContact] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [whatsAppSuccess, setWhatsAppSuccess] = useState(false);
  const [guestPopup, setGuestPopup] = useState(false);
  const [guestVerified, setGuestVerified] = useState(false);
  const [media, setMedia] = useState("");
  const [whatsappURL, setWhatsappURL] = useState("");
  const [propertySingleStore, setPropertySingleStore] = useState(null);
  let user_values = JSON.parse(sessionStorage.getItem("user_values"));
  const decryptedData = getDecryptedCookie(
    "device-infos",
    process.env.REACT_APP_DEVICE_KEY
  );
  const [placeholder, setPlaceholder] = useState("|");
  const txt = "Search for locality or landmark";
  const txtLen = txt.length;
  let char = 0;
  let animationFrameId;
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const containerRef = useRef(null);

  const idealProperties = [
    {
      name:
        ln && ln["plots_and_lands"] ? ln["plots_and_lands"] : "Plots and Lands",
      img: PropertyOne,
    },
    {
      name: ln && ln["residential"] ? ln["residential"] : "Residential",
      img: PropertyTwo,
    },
    {
      name: ln && ln["commercial"] ? ln["commercial"] : "Commercial",
      img: PropertyThree,
    },
    {
      name:
        ln && ln["agricultural_and_farm_lands"]
          ? ln["agricultural_and_farm_lands"]
          : "Agricultural / Farmlands",
      img: PropertyFour,
    },
  ];

  function handleDeleteProperty() {
    let body = {
      property_status:
        selectedProperty?.property_status === "Active"
          ? "Deactivated"
          : "Active",
    };

    dispatch(
      updatePropertyStatus(
        _token,
        selectedProperty?._id,
        setDeletePop,
        setDeleteFlag,
        body
      )
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLocationTab = (event, newValue) => {
    setLocationValue(newValue);
  };

  function handleAllProperties() {
    dispatch(getAllProperties(_token));
  }

  function handleAllUserProperties() {
    if (user_id) {
      dispatch(
        allUserProperties(
          _token,
          setMyProperties,
          user_id,
          sorting,
          propertyFilter
        )
      );
    }
  }

  function handleGetValues() {
    dispatch(getAllValues(setUserValues));
  }

  function handleHomeProperties() {
    dispatch(getHomePageProperties(setHomeProperties, centerPoint));
  }

  const handleContactPopClose = () => {
    setContactPopup(false);
    setContactPopupDetails({ phone: "", email: "", name: "" });
  };

  function handleWishlist(_id, title) {
    if (_token) {
      if (
        title === "Residential" ||
        title === "Commercial" ||
        title === "New Projects"
      ) {
        dispatch(addWishlist(heart, setHeart, _id, handleHomeProperties));
      } else {
        dispatch(addWishlist(heart, setHeart, _id, handleAllProperties));
      }
    } else {
      setLoginErr("Please login to add Whishlist");
    }
  }

  // const handleMarkerClick = (marker) => {
  //   let origin = {
  //     lat: 11.1010638,
  //     lng: 77.340542,
  //   };
  //   const destination = `${marker.lat},${marker.lng}`;
  //   const originCoord = `${origin.lat},${origin.lng}`;
  //   const directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${originCoord}&destination=${destination}`;
  //   window.open(directionsUrl, "_blank");
  // };

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  function handlePlaceChanged() {
    if (autocomplete !== null) {
      const place = autocomplete?.getPlace();
      if (place && place.formatted_address) {
        setInputValue(place.formatted_address);
        const location = place?.geometry?.location;
        if (location) {
          const lat = location?.lat();
          const lng = location?.lng();
          setSearchCoordinates({ lat, lng });
        } else {
          alert("Try again");
        }
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }

  function handleGetAllServices() {
    dispatch(allServiceList());
  }

  const handleGetContact = (e) => {
    const { name, value } = e.target;
    setGetContact({ ...getContact, [name]: value });
  };

  // const typeIt = () => {
  //   const humanize = Math.round(Math.random() * (200 - 30)) + 30;
  //   setTimeout(() => {
  //     char++;
  //     const type = txt.substring(0, char);
  //     setPlaceholder(type + '|');

  //     if (char < txtLen) {
  //       typeIt();
  //     } else {
  //       setPlaceholder(type);
  //       setTimeout(() => {
  //         char = 0;
  //         typeIt();
  //       }, 2000);
  //     }
  //   }, humanize);
  // };

  const handleCallClicked = (datas) => {
    setPropertySingleStore(datas);
    setContactPopupDetails({
      phone: datas?.created_user?.phone,
      email: datas?.created_user?.email,
      name: datas?.created_user?.name,
    });
    if (_token) {
      let save_count_body = {
        property_id: datas?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };
      // setContactPopup(true);
      dispatch(savePropertyCount(save_count_body, setContactPopup));
    } else if (compareDatesWithin24Hours(decryptedData?.c_date)) {
      let save_count_body = {
        property_id: datas?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };
      dispatch(savePropertyCount(save_count_body, setContactPopup));
    } else {
      setMedia("call");
      setGuestPopup(true);
    }
  };

  async function handleWhatsAppClicked(user_detail) {
    setPropertySingleStore(user_detail);
    let _content = `Hai ${user_detail?.created_user?.name},`;
    const prefilledMessage = await encodeURIComponent(_content);
    setWhatsappURL(
      `https://wa.me/${user_detail?.created_user?.phone}?text=${prefilledMessage}`
    );
    if (_token) {
      let save_count_body = {
        property_id: user_detail?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };
      dispatch(savePropertyCount(save_count_body, setWhatsAppSuccess));
    } else if (compareDatesWithin24Hours(decryptedData?.c_date)) {
      let save_count_body = {
        property_id: user_detail?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };
      dispatch(savePropertyCount(save_count_body, setWhatsAppSuccess));
    } else {
      setMedia("whatsapp");
      setGuestPopup(true);
    }
  }

  const scroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth * 0.3;
      if (direction === "left") {
        containerRef.current.scrollBy({
          left: -scrollAmount,
          behavior: "smooth",
        });
      } else if (direction === "right") {
        containerRef.current.scrollBy({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    const updateButtonVisibility = () => {
      if (containerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
        setShowPrev(scrollLeft > 0);
        setShowNext(scrollLeft + clientWidth < scrollWidth);
      }
    };

    // Attach the event listener
    containerRef?.current?.addEventListener("scroll", updateButtonVisibility);

    // Cleanup the event listener on component unmount
    return () => {
      containerRef?.current?.removeEventListener(
        "scroll",
        updateButtonVisibility
      );
    };
  }, []);

  useEffect(() => {
    if (whatsAppSuccess) {
      setWhatsAppSuccess(false);
      setTimeout(() => {
        window.open(whatsappURL, "_blank");
      }, 1000);
    }
  }, [whatsAppSuccess]);

  useEffect(() => {
    if (guestVerified && media == "call") {
      setGuestVerified(false);
      setMedia("");
      // setContactPopup(true);
      let save_count_body = {
        property_id: propertySingleStore?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };

      dispatch(savePropertyCount(save_count_body, setContactPopup));
    } else if (guestVerified && media == "whatsapp") {
      setGuestVerified(false);
      setMedia("");

      let save_count_body = {
        property_id: propertySingleStore?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };

      dispatch(savePropertyCount(save_count_body, setWhatsAppSuccess));
      // window.open(whatsappURL, '_blank');
    }
  }, [guestVerified]);

  useEffect(() => {
    const typeIt = () => {
      const humanize = Math.round(Math.random() * (200 - 30)) + 30;
      animationFrameId = requestAnimationFrame(() => {
        setTimeout(() => {
          char++;
          const type = txt.substring(0, char);
          setPlaceholder(type + "|");

          if (char < txtLen) {
            typeIt();
          } else {
            setPlaceholder(type); // remove the '|'
            setTimeout(() => {
              // Reset char and restart animation
              char = 0;
              typeIt();
            }, 2000); // Wait for 2 seconds before restarting
          }
        }, humanize);
      });
    };

    typeIt();

    return () => cancelAnimationFrame(animationFrameId);
  }, []); // Empty dependency array means this effect runs only once

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    handleGetAllServices();
    // typeIt();
  }, []);

  useEffect(() => {
    if (autocomplete && inputRef.current) {
      autocomplete.addListener("place_changed", handlePlaceChanged);
    }

    return () => {
      if (autocomplete && inputRef.current) {
        window?.google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [autocomplete]);

  useEffect(() => {
    handleAllProperties();
    handleAllUserProperties();
    handleHomeProperties();
    handleGetValues();
    const handleScroll = () => {
      const heightThreshold = 200;

      if (window.scrollY > heightThreshold) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    localStorage.removeItem("error");
    sessionStorage.clear();
    dispatch(clearPropertyData(null));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (deleteFlag) {
      setDeleteFlag(false);
      handleAllUserProperties();
    }
  }, [deleteFlag]);

  useEffect(() => {
    handleHomeProperties();
  }, [centerPoint]);

  useEffect(() => {
    let my_res = JSON.parse(sessionStorage.getItem("property_info"));

    if (my_res?.property_cordinates?.coordinates) {
      setCenterPoint({
        lat: my_res?.property_cordinates?.coordinates[0],
        lng: my_res?.property_cordinates?.coordinates[1],
      });
    } else {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          const new_center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenterPoint(new_center);
        });
      } else {
        alert("Geolocation is not available in your browser.");
      }
    }
  }, []);

  useEffect(() => {
    let Con_details = JSON.parse(localStorage.getItem("userToken"));
    if (Con_details) {
      setGetContact({
        ...getContact,
        name: Con_details?.name,
        phone: Con_details?.phone,
        email: Con_details?.email,
      });
    }
    console.log("i am from gazam");
  }, []);

  // const sendDataToParent = () => {
  //   const data = { message: 'Hello from iframe!',status:200, game:"Completed" ,timestamp: Date.now()};
  //   console.log('Sending message to parent:', data);
  //   window.parent.postMessage(data, 'http://localhost:3000/home'); // Replace with the parent's origin
  // };

  // // Send data when the iframe content is loaded
  // useEffect(() => {
  //   sendDataToParent();
  // }, []);

  return (
    <Box overflow={"hidden"}>
      <Box
        className={classes.sub_menus}
        display={{ xs: "none", sm: "flex", md: "flex" }}
      >
        <Dropdown />
      </Box>
      <Box
        position={"relative"}
        data-aos="zoom-in"
        height={{ xs: "170px", sm: "200px", md: "275px" }}
      >
        <img src={Banner} alt="image" width={"100%"} height={"100%"} />
        <Box
          className={classes.tabs}
          display={{ xs: "none", sm: "block", md: "block" }}
        >
          {/* <Box className="homepage-tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab
                value="one"
                label={
                  ln && ln["buy_property"] ? ln["buy_property"] : "Buy Property"
                }
              />
              <Tab
                value="two"
                label={
                  ln && ln["sell_property"]
                    ? ln["sell_property"]
                    : "Sell Property"
                }
                onClick={() => {
                  dispatch(getAllValues());
                  navigate("/postproperty");
                }}
              />
              <Tab
                value="three"
                label={ln && ln["services"] ? ln["services"] : "Services"}
              />
              <Tab
                value="four"
                label={
                  ln && ln["nri_services"] ? ln["nri_services"] : "NRI Services"
                }
              />
            </Tabs>
          </Box> */}
        </Box>
      </Box>

      <Box className="d-flex-center">
       
        <Box display={"inline-block"} className="position-relative">
        <Box className="homepage-tabs">
          <button className={classes.animi_btns}>
            <div className={classes.text}>
              <span>Buy</span>
              <span>Property</span>
            </div>
            <div className={classes.clone}>
              <span>Buy</span>
              <span>Property</span>
            </div>
          </button>
          <button className={classes.animi_btns}>
            <div className={classes.text}>
              <span>Sell</span>
              <span>Property</span>
            </div>
            <div className={classes.clone}>
              <span>Sell</span>
              <span>Property</span>
            </div>
          </button>
          <button className={`${classes.animi_btns} ${classes.animi_btns_two}`}>
            <div className={classes.text}>
              <span>Services</span>
            </div>
            <div className={classes.clone}>
              <span>Services</span>
            </div>
          </button>
          <button className={classes.animi_btns}>
            <div className={classes.text}>
              <span>NRI</span>
              <span>Services</span>
            </div>
            <div className={classes.clone}>
              <span>NRI</span>
              <span>Services</span>
            </div>
          </button>
        </Box>
          <Box className={classes.search_part}>
            <Box display={{ xs: "none", sm: "block", md: "block" }}>
              <TextField
                select
                value={homePropertyFilter}
                className="location-select new-select"
                // onChange={(e) => setHomePropertyFilter(e.target.value)}
              >
                <MenuItem value="-1" hidden>
                  Property Type
                </MenuItem>
                {user_values?.kind_of_property?.map((datas) => (
                  <MenuItem
                    value={datas.text}
                    onClick={(e) => {
                      setHomePropertyFilter(datas.text);
                      setHomePropertyFilterId(datas._id);
                    }}
                  >
                    {datas.text}
                  </MenuItem>
                ))}
                {/* <MenuItem value="Residencial">Residencial</MenuItem>
          <MenuItem value="Commercial">Commercial</MenuItem>
          <MenuItem value="Land/Plots">Land/Plots</MenuItem>
          <MenuItem value="Agricultural/ Farmlands">
            Agricultural/ Farmlands
          </MenuItem> */}
              </TextField>
            </Box>

            <GoogleAutocomplete
              onLoad={handleLoad}
              onPlaceChanged={handlePlaceChanged}
            >
              <TextField
                inputRef={inputRef}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                // placeholder="Search for locality, landmark, project, or builder "
                placeholder={placeholder}
                className="search-field home-search-field"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </GoogleAutocomplete>
            <Button
              className={classes.search_btn}
              onClick={() => {
                localStorage.setItem("search-input", inputValue);
                localStorage.setItem(
                  "search-input-filter",
                  homePropertyFilterId
                );
                localStorage.setItem("search-input-name", homePropertyFilter);
                navigate(
                  `/search-results/${searchCoordinates?.lat},${searchCoordinates?.lng}`
                );
              }}
              disabled={!searchCoordinates}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Box>
      <Container>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"end"}
          mt={5}
          flexWrap={"wrap"}
        >
          <Box>
            <Typography className={classes.box_title}>
              {ln && ln["property_in_high_demands"]
                ? ln["property_in_high_demands"]
                : "Property in High Demands"}
            </Typography>
            <span className={classes.box_sub_title}>
              {ln && ln["tailored_to_your_lifestyle_and_preferences"]
                ? ln["tailored_to_your_lifestyle_and_preferences"]
                : "Tailored to Your Lifestyle and Preferences"}
            </span>
          </Box>
          <Typography
            className={classes.view_all}
            onClick={() => {
              sessionStorage.setItem("clicked-val", "Property in High Demands");
              navigate("/search-results/allproperties");
            }}
          >
            {ln && ln["view_all_property"]
              ? ln["view_all_property"]
              : "View All Property"}
          </Typography>
        </Box>
        <Box data-aos="fade-up">
          <PropertySlider
            properties={allProperties?.properties}
            title="all"
            handleWishlist={handleWishlist}
          />
        </Box>
      </Container>
      <Box bgcolor={"#C6EE90"} py={3} data-aos="zoom-in">
        <Container>
          <Grid container>
            <Grid item xs={12} sm={12} md={7}>
              <Box height={"100%"} mt={5}>
                <Typography className={classes.box_title}>
                  <b>
                    {ln && ln["invest_in_agricultural/farmlands"]
                      ? ln["invest_in_agricultural/farmlands"]
                      : "Invest in Agricultural/Farmlands"}
                  </b>
                </Typography>
                <Typography className={classes.box_sub_title}>
                  {ln && ln["tailored_to_your_lifestyle_and_preferences"]
                    ? ln["tailored_to_your_lifestyle_and_preferences"]
                    : "Tailored to Your Lifestyle and Preferences"}
                </Typography>
                <Typography
                  className={classes.find_property}
                  onClick={() => navigate("/search-results/allproperties")}
                >
                  {ln && ln["find_your_property"]
                    ? ln["find_your_property"]
                    : "Find Your Property"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={1}></Grid>
            <Grid item xs={12} sm={12} md={4}>
              <img
                src={FindProperty}
                alt="image"
                width={"90%"}
                height={"250px"}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box mt={4}>
        <Container>
          <Box>
            {_token && myProperties?.properties.length > 0 && (
              <>
                <Box
                  display={{ xs: "block", sm: "flex", md: "flex" }}
                  justifyContent={"space-between"}
                  alignItems={"end"}
                  mt={5}
                >
                  <Box>
                    <Typography className={classes.my_list_title}>
                      {ln && ln["your_listings"]
                        ? ln["your_listings"]
                        : "Your Listings"}
                    </Typography>
                  </Box>

                  <Typography
                    className={classes.view_all}
                    onClick={() => navigate("/alllistings")}
                  >
                    {ln && ln["view_all_property"]
                      ? ln["view_all_property"]
                      : "View All Property"}
                  </Typography>
                </Box>
                <Box my={2}>
                  {myProperties?.properties.length > 0 &&
                    myProperties?.properties.slice(0, 1).map((datas, i) => {
                      return (
                        <Box
                          boxShadow={"0 0 10px rgba(0, 0, 0, 0.2)"}
                          borderRadius={"12px"}
                          mb={3}
                          key={i}
                        >
                          <Grid container>
                            <Grid item xs={12} sm={12} md={3}>
                              <img
                                src={
                                  datas.photos[0]?.image
                                    ? `${process.env.REACT_APP_API_URL}/${datas.photos[0]?.image}`
                                    : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                                }
                                alt="image"
                                width={"100%"}
                                height={"200px"}
                                style={{
                                  objectFit: "contain",
                                  minHeight: "200px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={9}>
                              <Box className={classesOne.single_property}>
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                >
                                  <Box
                                    className={classesOne.property_header}
                                    width={{ xs: "56%", sm: "70%", md: "100%" }}
                                  >
                                    <Typography
                                    // sx={{ cursor: "pointer" }}
                                    // onClick={() =>
                                    //   navigate(`/singleproperty/${datas._id}`)
                                    // }
                                    >
                                      {datas.offer_type} {datas.property_type}{" "}
                                      {datas.kind_of_property}{" "}
                                      {datas.property_status === "Drafted" ? (
                                        <span className={classesOne.draft_list}>
                                          Draft
                                        </span>
                                      ) : datas.property_status ===
                                        "Pending_Approval" ? (
                                        <span
                                          className={classesOne.pending_list}
                                        >
                                          Pending
                                        </span>
                                      ) : datas.property_status === "Active" ? (
                                        <span
                                          className={classesOne.active_list}
                                        >
                                          Active
                                        </span>
                                      ) : (
                                        datas.property_status ===
                                          "Deactivated" && (
                                          <span
                                            className={classesOne.deactive_list}
                                          >
                                            Deactivated
                                          </span>
                                        )
                                      )}
                                    </Typography>
                                    <span>{datas.location}</span>
                                  </Box>
                                  {/* <Box className="d-flex" mt={4}>
                              <MdEdit
                                className={classesOne.edit_icon}
                                onClick={() => {
                                 
                                    dispatch(singleProperty(_token,datas._id,setPropertyDetails,navigate,"edit"));
                                
                                 
                                }}
                              />
                              <MdDelete
                                className={classesOne.del_icon}
                                onClick={() => {
                                  setSelectedProperty(datas);
                                  setDeletePop(true);
                                }}
                              />
                            </Box> */}
                                </Box>
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  flexWrap={"wrap"}
                                >
                                  <Box display={"flex"} gap={"70px"}>
                                    <Box
                                      className={
                                        classesOne.single_property_details
                                      }
                                    >
                                      <small>Price</small>
                                      <span>
                                        ₹
                                        {formatNumber(
                                          datas.price?.expected_price
                                        )}
                                      </span>
                                    </Box>
                                    <Box
                                      className={
                                        classesOne.single_property_details
                                      }
                                    >
                                      <small>Property ID</small>
                                      <span>{datas._id.slice(0, 7)}</span>
                                    </Box>
                                    <Box
                                      className={
                                        classesOne.single_property_details
                                      }
                                    >
                                      <small>Duration</small>
                                      <span>
                                        {" "}
                                        Posted{" "}
                                        {countDaysFromDateToNow(
                                          datas.createdAt
                                        )}{" "}
                                        days ago
                                      </span>
                                    </Box>
                                  </Box>
                                  <Box mt={{ xs: 3, sm: 0, md: 0 }}>
                                    <TextField
                                      select
                                      value={"Manage Listing"}
                                      className="manage-listing"
                                    >
                                      <MenuItem
                                        value="Preview"
                                        onClick={() =>
                                          navigate(
                                            `/singleproperty/${datas._id}`
                                          )
                                        }
                                      >
                                        Preview
                                      </MenuItem>
                                      <MenuItem
                                        value="Add/Edit Details"
                                        onClick={() => {
                                          dispatch(
                                            singleProperty(
                                              _token,
                                              datas._id,
                                              setPropertyDetails,
                                              navigate,
                                              "edit"
                                            )
                                          );
                                        }}
                                      >
                                        Add/Edit Details
                                      </MenuItem>
                                      <MenuItem
                                        value="Deactivate"
                                        onClick={() => {
                                          setSelectedProperty(datas);
                                          setDeletePop(true);
                                        }}
                                        hidden={
                                          datas.property_status !== "Active" &&
                                          datas.property_status !==
                                            "Deactivated"
                                        }
                                      >
                                        {datas.property_status === "Active"
                                          ? "Deactivate"
                                          : "Activate"}
                                      </MenuItem>
                                      <MenuItem value="Upgrade">
                                        Upgrade
                                      </MenuItem>
                                      <MenuItem value="Manage Listing" hidden>
                                        Manage Listing
                                      </MenuItem>
                                    </TextField>
                                  </Box>
                                </Box>
                                <span className={classesOne.plan_type}>
                                  Free Plan
                                </span>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })}
                </Box>
                <Box className={classes.property_res}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                    flexWrap={"wrap"}
                  >
                    <Box>
                      <Typography fontWeight={"600"} fontSize={"27px"}>
                        1{" "}
                        {ln && ln["response_on_this_posting"]
                          ? ln["response_on_this_posting"]
                          : "Response on this Posting"}
                      </Typography>
                    </Box>
                    <Link to="/search-results/allproperties" className={classes.view_all}>
                      {ln && ln["view_all_responses"]
                        ? ln["view_all_responses"]
                        : " View all responses"}
                    </Link>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                    mt={5}
                    flexWrap={"wrap"}
                  >
                    <Box>
                      <Typography fontWeight={"600"} fontSize={"20px"}>
                        You are missing out on upto 10+ buyer responses with
                        free plan
                      </Typography>
                      <span>
                        {ln &&
                        ln[
                          "upgraded_postings_appear_higher_in_searches_and_are_seen_by_more_buyers"
                        ]
                          ? ln[
                              "upgraded_postings_appear_higher_in_searches_and_are_seen_by_more_buyers"
                            ]
                          : "Upgraded postings appear higher in searches and are seen by more buyers"}
                      </span>
                    </Box>
                    <Button
                      className={classes.search_btn}
                      sx={{ marginTop: { xs: 2, sm: 0, md: 0 } }}
                    >
                      Upgrade Now
                    </Button>
                  </Box>
                </Box>{" "}
              </>
            )}
            <Box>
              <Plans />
            </Box>
          </Box>
          <Box mb={6} mt={6}>
            <Carousel
              responsive={responsive}
              autoPlaySpeed={1500}
              infinite={true}
              arrows={true}
              className=""
            >
              {Array.isArray(asset) &&
                asset.map((datas, index) => (
                  <Box>
                    <img
                      src={datas.img}
                      alt="image"
                      // height={"230px"}
                      width={"100%"}
                    />
                  </Box>
                ))}
            </Carousel>
          </Box>
          <Box position={"relative"}>
            {showNext && (
              <MdNavigateNext
                className={classes.services_next}
                onClick={() => scroll("right")}
              />
            )}
            {showPrev && (
              <GrFormPrevious
                className={classes.services_prev}
                onClick={() => scroll("left")}
              />
            )}
            <Box>
              <Typography
                className={classes.box_title}
                onClick={() => scroll("left")}
              >
                {ln && ln["our_services"] ? ln["our_services"] : "Our Services"}
              </Typography>
              <span className={classes.box_sub_title}>
                {ln && ln["comprehensive_real_estate_solutions"]
                  ? ln["comprehensive_real_estate_solutions"]
                  : " Comprehensive Real Estate Solutions"}
              </span>
            </Box>
            <Box
              data-aos="fade-up"
              className={classes.services_wrapper}
              ref={containerRef}
            >
              {/* <MdNavigateNext className={classes.services_next} /> */}
              {Array.isArray(servicesList) &&
                servicesList?.map((datas) => (
                  <Box
                    className={`${classes.services} ${classes.hover_plans}`}
                    onClick={() => {
                      // setInputs({ ...inputs, service: "Land Survey" });
                      // setServicePop(true);
                      navigate(`/nri-services/${datas?._id}`);
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}${datas?.logo}`}
                    />
                    <Typography>{datas?.name}</Typography>
                  </Box>
                ))}
              {/* <Grid container spacing={2} mt={1}>
                {Array.isArray(servicesList) &&
                  servicesList?.map((datas)=>(
                    <Grid item xs={6} sm={6} md={2}>
                  <Box className={classes.services}>
                    <img src={`${process.env.REACT_APP_API_URL}${datas?.logo}`} />
                    <Typography>
                      {datas?.name}
                    </Typography>
                  </Box>
                </Grid>
                  ))
                } */}
              {/* <Grid item xs={6} sm={6} md={2}>
                  <Box className={classes.services}>
                    <ServiceOneIcon />
                    <Typography>
                      {" "}
                      {ln && ln["legal_opinion"]
                        ? ln["legal_opinion"]
                        : "Legal Opinion"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <Box className={classes.services}>
                    <ServiceTwoIcon />
                    <Typography>
                      {ln && ln["land_survey"]
                        ? ln["land_survey"]
                        : "Land Survey "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <Box className={classes.services}>
                    <ServiceThreeIcon />
                    <Typography>
                      {" "}
                      {ln && ln["building_permission"]
                        ? ln["building_permission"]
                        : "Building Permission"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <Box className={classes.services}>
                    <ServiceFourIcon />
                    <Typography>
                      {" "}
                      {ln && ln["land_monitoring_services"]
                        ? ln["land_monitoring_services"]
                        : "Land Monitoring Services"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <Box className={classes.services}>
                    <ServiceFiveIcon />
                    <Typography>
                      {" "}
                      {ln && ln["layout_approvals"]
                        ? ln["layout_approvals"]
                        : "Layout Approvals"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <Box className={classes.services}>
                    <ServiceSixIcon />
                    <Typography>
                      {" "}
                      {ln && ln["land_development_services"]
                        ? ln["land_development_services"]
                        : "Land Development services"}
                    </Typography>
                  </Box>
                </Grid> */}
              {/* </Grid> */}
            </Box>
          </Box>
          <Box mt={5}>
            <Box>
              <Typography className={classes.box_title}>
                {ln && ln["search_your_nearby_properties"]
                  ? ln["search_your_nearby_properties"]
                  : "Search Your Nearby Properties"}
              </Typography>
              <span className={classes.box_sub_title}>
                {ln && ln["discover_properties_near_you_easily"]
                  ? ln["discover_properties_near_you_easily"]
                  : "Discover Properties Near You Easily"}
              </span>
            </Box>
            {/* <Box className="location-tabs" mt={3}>
              <Tabs
                value={locationValue}
                onChange={handleLocationTab}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value="one" label="Hyderabad" />
                <Tab value="two" label="Secunderabad" />
                <Tab value="three" label="Warangal" />
                <Tab value="four" label="Nizamabad" />
              </Tabs>
            </Box> */}
            <Box height={"400px"} mt={3}>
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={centerPoint}
                  zoom={10}
                >
                  {Array.isArray(homeProperties?.cordinates) &&
                    homeProperties?.cordinates.map((marker, index) => (
                      <Marker
                        key={index}
                        position={{
                          lat: marker?.centroid[1],
                          lng: marker?.centroid[0],
                        }}
                        icon={customIcon1}
                        onClick={() => handleMarkerClick(marker)}
                      />
                    ))}
                  {selectedMarker && (
                    <InfoWindow
                      position={{
                        lat: selectedMarker?.centroid[1],
                        lng: selectedMarker?.centroid[0],
                      }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div>
                        <h5>{selectedMarker?._id}</h5>
                        <p>
                          Found <b>{selectedMarker?.count}</b> properties here.{" "}
                        </p>
                        {/* Add more details as needed */}
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              ) : (
                <Typography>Loading 12345...</Typography>
              )}
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"end"}
            mt={5}
            flexWrap={"wrap"}
          >
            <Box>
              <Typography className={classes.box_title}>
                {ln && ln["new_projects"] ? ln["new_projects"] : "New Projects"}
              </Typography>
              <span className={classes.box_sub_title}>
                {ln &&
                ln["find_the_ideal_land_for_your_dream_home_or_investment"]
                  ? ln["find_the_ideal_land_for_your_dream_home_or_investment"]
                  : "Find the Ideal Land for Your Dream Home or Investment"}
              </span>
            </Box>
            <Typography
              className={classes.view_all}
              onClick={() => {
                sessionStorage.setItem("clicked-val", "New Projects");
                navigate("/search-results/allproperties");
              }}
            >
              {ln && ln["view_all_property"]
                ? ln["view_all_property"]
                : "View All Property"}
            </Typography>
          </Box>
          <Box data-aos="fade-up">
            <PropertySlider
              // properties={allProperties?.properties}
              properties={homeProperties?.newProjects}
              title="New Projects"
              handleWishlist={handleWishlist}
            />
          </Box>
          <Box
            bgcolor={"#C6EE90"}
            p={3}
            my={10}
            borderRadius={"10px"}
            data-aos="flip-left"
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={8}>
                <Box height={"100%"} mt={5}>
                  <Typography className={classes.box_title_two}>
                    {ln && ln["looking_to_sell_your_property"]
                      ? ln["looking_to_sell_your_property"]
                      : "Looking to sell your property. Its Easy! Call our Experts"}
                  </Typography>
                  <Typography className={classes.box_sub_title}>
                    {ln && ln["seek_expert_guidance"]
                      ? ln["seek_expert_guidance"]
                      : "Seek expert guidance, dial our helpline for a smoother real estate journey"}
                  </Typography>
                  <Box
                    className="d-flex"
                    gap={"20px"}
                    flexWrap={"wrap"}
                    mt={3}
                    mb={{ xs: 2 }}
                  >
                    <Button className={classes.callnow_btns}>
                      <CallNowIcon />
                      {ln && ln["call_now"] ? ln["call_now"] : "Call Now"}
                    </Button>
                    <Button className={classes.post_whatsapp}>
                      <WhatsAppIcon />
                      {ln && ln["post_in_watsapp"]
                        ? ln["post_in_watsapp"]
                        : "Post in Watsapp"}
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={1}></Grid>
              <Grid item xs={12} sm={12} md={3}>
                <img
                  src={Help}
                  alt="image"
                  // width={"90%"}
                  // height={"250px"}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box>
              <Typography className={classes.box_title}>
                {ln && ln["agricultural_and_farm_lands"]
                  ? ln["agricultural_and_farm_lands"]
                  : "Agricultural and Farm Lands"}
              </Typography>
              <span className={classes.box_sub_title}>
                {ln && ln["cultivate_your_farm_dreams"]
                  ? ln["cultivate_your_farm_dreams"]
                  : "Cultivate Your Farm Dreams"}
              </span>
            </Box>
            <Box>
              <Grid container spacing={3}>
                {Array.isArray(homeProperties?.agricultureAndFarmlands) &&
                  homeProperties?.agricultureAndFarmlands.length > 0 &&
                  homeProperties?.agricultureAndFarmlands
                    .slice(0, 2)
                    .map((datas, i) => (
                      <Grid item xs={12} sm={6} md={6}>
                        <Box height={"350px"} position={"relative"}>
                          <img
                            src={
                              datas?.photos[0]?.image
                                ? `${process.env.REACT_APP_API_URL}/${datas?.photos[0]?.image}`
                                : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                            }
                            alt="image"
                            width={"100%"}
                            height={"100%"}
                          />
                          <Box className={classes.inner_land}>
                            <Box className="d-flex-between">
                              <Box>
                                <Typography className={classes.land_title}>
                                  {datas.created_user?.name}
                                </Typography>
                                <Typography className={classes.land_sub_title}>
                                  {datas.location}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography className={classes.land_title}>
                                  ₹ {formatNumber(datas.price?.expected_price)}
                                </Typography>
                                <Typography className={classes.land_sub_title}>
                                  ₹15,000/sq.ft
                                </Typography>
                              </Box>
                            </Box>
                            <Typography className={classes.land_title} mt={2}>
                              {datas.area_of_property} Sq.Ft.
                            </Typography>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              gap={"15px"}
                              justifyContent={"center"}
                              mt={2}
                              flexWrap={"wrap"}
                            >
                              <Button
                                className={classes.land_contact_btn}
                                //  href= {`https://wa.me/+91${datas?.created_user?.phone}`}
                                // url="https://web.whatsapp.com/"
                                // target="_blank"
                                onClick={() => handleWhatsAppClicked(datas)}
                              >
                                <WhatsappIcon /> WhatsApp
                              </Button>
                              <Button className={classes.land_contact_btn}>
                                <ChatIcon /> SMS
                              </Button>
                              <Button
                                className={classes.land_contact_btn}
                                onClick={() => handleCallClicked(datas)}
                              >
                                <ContactIcon /> Call
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
              </Grid>
            </Box>
          </Box>
          <Box mt={13}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.no_buyers} data-aos="fade-up">
                  <Typography>
                    {ln && ln["no_buyers"]
                      ? ln["no_buyers"] + "?"
                      : "No Buyers?"}
                    <span>
                      {" "}
                      {ln && ln["get_2x_buyers"]
                        ? ln["get_2x_buyers"]
                        : "Get 2x Buyers"}
                    </span>{" "}
                  </Typography>
                  <Button sx={{ padding: "8px 31px" }}>
                    {ln && ln["upgrade_to_premium_now"]
                      ? ln["upgrade_to_premium_now"]
                      : "Upgrade to premium Now!"}
                  </Button>
                  <img
                    src={LockKey}
                    alt="image"
                    className={classes.no_buyer_icon}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.no_buyers} data-aos="fade-up">
                  <Typography>
                    {ln && ln["looking_to_post_your_property_for_free"]
                      ? ln["looking_to_post_your_property_for_free"]
                      : "Looking to post your property for FREE"}
                  </Typography>
                  <Button sx={{ padding: "8px 10px 8px 25px" }}>
                    {ln && ln["post_property"]
                      ? ln["post_property"]
                      : "Post Property"}{" "}
                    <span className={classes.free_property}>
                      {" "}
                      {ln && ln["free"] ? ln["free"] : "Free"}
                    </span>
                  </Button>
                  <img
                    src={PhoneInHand}
                    alt="image"
                    className={classes.phone_in_hand_icon}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"end"}
            mt={5}
            flexWrap={"wrap"}
          >
            <Box>
              <Typography className={classes.box_title}>
                {ln && ln["residencial_properties"]
                  ? ln["residencial_properties"]
                  : "Residencial Properties"}
              </Typography>
              <span className={classes.box_sub_title}>
                {ln && ln["tailored_to_your_lifestyle_and_preferences"]
                  ? ln["tailored_to_your_lifestyle_and_preferences"]
                  : "Tailored to Your Lifestyle and Preferences"}
              </span>
            </Box>
            <Typography
              className={classes.view_all}
              onClick={() => {
                sessionStorage.setItem("clicked-val", "Residential");
                navigate("/search-results/allproperties");
              }}
            >
              {ln && ln["view_all_property"]
                ? ln["view_all_property"]
                : "View All Property"}
            </Typography>
          </Box>
          <Box data-aos="fade-up">
            <PropertySlider
              // properties={allProperties?.properties}
              properties={homeProperties?.residentials}
              title="Residential"
              handleWishlist={handleWishlist}
            />
          </Box>
          <Box
            display={{ xs: "block", sm: "flex", md: "flex" }}
            justifyContent={"space-between"}
            alignItems={"end"}
            mt={5}
            flexWrap={"wrap"}
          >
            <Box>
              <Typography className={classes.box_title}>
                {ln && ln["commercial_properties"]
                  ? ln["commercial_properties"]
                  : "Commercial Properties"}
              </Typography>
              <span className={classes.box_sub_title}>
                {ln && ln["discover_prime_business_spaces"]
                  ? ln["discover_prime_business_spaces"]
                  : "Discover Prime Business Spaces"}
              </span>
            </Box>
            <Typography
              className={classes.view_all}
              onClick={() => {
                sessionStorage.setItem("clicked-val", "Commercial");
                navigate("/search-results/allproperties");
              }}
            >
              {ln && ln["view_all_property"]
                ? ln["view_all_property"]
                : "View All Property"}
            </Typography>
          </Box>
          <Box data-aos="fade-up">
            <PropertySlider
              // properties={allProperties?.properties}
              properties={homeProperties?.commercial}
              title="Commercial"
              handleWishlist={handleWishlist}
            />
          </Box>
          <Box mt={5} mb={3}>
            <Box>
              <Typography className={classes.box_title}>
                {ln && ln["discover_your_ideal_properties"]
                  ? ln["discover_your_ideal_properties"]
                  : "Discover Your Ideal Properties"}
              </Typography>
            </Box>
            <Box data-aos="zoom-out">
              <Grid container mt={1} spacing={2}>
                {idealProperties.map((datas) => (
                  <Grid item xs={6} sm={6} md={3}>
                    <Box
                      height={{ xs: "200px", sm: "200px", md: "280px" }}
                      boxShadow={"0 0 12px rgba(0,0,0,0.1)"}
                      textAlign={"center"}
                    >
                      <img
                        src={datas.img}
                        alt="image"
                        width={"100%"}
                        height={"83%"}
                      />
                      <Typography height={"17%"} pt={"10px"}>
                        {datas.name}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Container>

        <DeleteConfirmation
          open={deletePop}
          handleClose={() => setDeletePop(false)}
          handleDeleteProperty={handleDeleteProperty}
          selectedProperty={selectedProperty}
        />
        {/* <ContactOwnerPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          handleGetContact={handleGetContact}
          getContact={getContact}
        /> */}
        <ContactPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          // handleGetContact={handleGetContact}
          // getContact={getContact}
        />
      </Box>
      {isScroll && (
        <Typography
          className={classes.scroll_top}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <Fab size="small" aria-label="scroll back to top">
            <FaChevronUp />
          </Fab>
        </Typography>
      )}
      {loginErr && (
        <AlertBox
          type={"error"}
          message={loginErr}
          stateName={() => setLoginErr("")}
        />
      )}
      {guestPopup && (
        <GuestUserPop
          open={guestPopup}
          handleClose={() => setGuestPopup(false)}
          media={media}
          setGuestVerified={setGuestVerified}
        />
      )}
    </Box>
  );
};

export default HomePage;
