import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
  Radio,
  Tabs,
  Tab,
  Checkbox,
} from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./property.css";
import BuildingOne from "../../Assets/Images/building-1.png";
import AdImg from "../../Assets/Images/ad.png";
import More from "../../Assets/Images/more.png";
import classes from "./property.module.css";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import DeleteConfirmation from "../popup/DeleteConfirmation";
import { useDispatch, useSelector } from "react-redux";
import {
  allProperties,
  deleteProperty,
  singleProperty,
  addWishlist,
  createContactOwner,
  successAlertAction,
  clearPropertyData,
  savePropertyCount,
} from "../../Redux/Actions/userActions";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import {
  ChatIcon,
  ContactIcon,
  DownArrowIcon,
  HeartIcon,
  HistoryIcon,
  ShareIcon,
  WhatsappIcon,
} from "../../Assets/Icons/icons";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Convertdate,
  Numeric,
  compareDatesWithin24Hours,
  convertPhone,
  formatNumber,
  getDecryptedCookie,
  splitFirstLetter,
} from "../../Utilities/commonFunctions";
import ImageSlider from "../popup/ImageSlider";
import PropertySlider from "../Home/PropertySlider";
import ContactPop from "../popup/ContactPop";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import ContactOwnerPop from "../popup/ContactOwnerPop";
import AlertBox from "../../Components/AlertBox";
import GuestUserPop from "../popup/GuestUserPop";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

let about_content =
  "This modern Commercial Plot designed with luxury facilities in the heart of Bangalore at Benson Town is an ideal investment option. Built as per high-quality construction standards. Theproperty is available for a price of 45.86 Lakh. Contact us formore information.";

function SinglePropertyDetails() {
  const [contactPopup, setContactPopup] = useState(false);
  const [contactPopupDetail, setContactPopupDetails] = useState({
    phone: "",
    email: "",
    name: "",
  });
  const [getContact, setGetContact] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [loginErr, setLoginErr] = useState("");
  const [imagePop, setImagePop] = useState(false);
  const [heart, setHeart] = useState(false);
  const [readAbout, setReadAbout] = useState(false);
  const [value, setValue] = useState("one");
  const [isOwnerChecked, setIsOwnerChecked] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [whatsAppSuccess, setWhatsAppSuccess] = useState(false);
  const [guestPopup, setGuestPopup] = useState(false);
  const [guestVerified, setGuestVerified] = useState(false);
  const [viewed, setViewed] = useState(false);
  const [media, setMedia] = useState("");
  const [whatsappURL, setWhatsappURL] = useState("");
  const [propertySingleStore, setPropertySingleStore] = useState(null);
  const location = useLocation();
  const my_params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
  let user_id = JSON.parse(localStorage.getItem("userToken"))?.id;
  const decryptedData = getDecryptedCookie(
    "device-infos",
    process.env.REACT_APP_DEVICE_KEY
  );

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setImagePop(false);
  };

  const handleContactPopClose = () => {
    setContactPopup(false);
    setContactPopupDetails({ phone: "", email: "", name: "" });
  };

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "phone") {
      setInputs({ ...inputs, [name]: value.slice(0, 10) });
    } else {
      setInputs({ ...inputs, [name]: value });
    }
  }

  function handleSingleProperty() {
    dispatch(
      singleProperty(
        _token,
        my_params?.id,
        setPropertyDetails,
        navigate,
        "single"
      )
    );
  }

  function handlePriceDetailsList(_details) {
    let new_details = _details?.map((el) => <li>{el}</li>);
    return new_details;
  }

  function handleReplace(word) {
    return word?.replaceAll("_", " ");
  }

  function handleWishlist(prop_id, title) {
    if (_token) {
      if (prop_id === "12lop") {
        dispatch(
          addWishlist(heart, setHeart, my_params?.id, handleSingleProperty)
        );
      } else {
        dispatch(addWishlist(heart, setHeart, prop_id, handleSingleProperty));
      }
    } else {
      setLoginErr("Please login to add Whishlist");
    }
  }

  function handleContactOwner() {
    let userEntry = {
      ...inputs,
      user_id: user_id,
      property_id: my_params?.id,
      owner_id: propertyDetails?.modifiedData?.created_user?.id,
      user_id: user_id,
    };
    dispatch(createContactOwner(_token, userEntry, setInputs));
    setIsOwnerChecked(false);
  }

  function handleShareProperty() {
    if (navigator.share) {
      const shareData = {
        title: "Gazam",
        text: "Gazam Property details",
        url: window.location.href,
      };

      navigator.share(shareData);
    } else {
      alert("Sorry! Your browser does not support Web Share API");
    }
  }

  function handleCheckBox(e) {
    if (e?.target?.checked) {
      setIsOwnerChecked(true);
    } else {
      setIsOwnerChecked(false);
    }
  }

  const handleGetContact = (e) => {
    const { name, value } = e.target;
    setGetContact({ ...getContact, [name]: value });
  };

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  const handleCallClicked = (datas) => {
    setPropertySingleStore(datas);
    setContactPopupDetails({
      phone: datas?.created_user?.phone,
      email: datas?.created_user?.email,
      name: datas?.created_user?.name,
    });
    if (_token) {
      let save_count_body = {
        property_id: datas?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };
      // setContactPopup(true);
      dispatch(savePropertyCount(save_count_body, setContactPopup));
    } else if (compareDatesWithin24Hours(decryptedData?.c_date)) {
      let save_count_body = {
        property_id: datas?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };
      dispatch(savePropertyCount(save_count_body, setContactPopup));
    } else {
      setMedia("call");
      setGuestPopup(true);
    }
  };

  async function handleWhatsAppClicked(user_detail) {
    setPropertySingleStore(user_detail);
    let _content = `Hai ${user_detail?.created_user?.name},`;
    const prefilledMessage = await encodeURIComponent(_content);
    setWhatsappURL(
      `https://wa.me/${user_detail?.created_user?.phone}?text=${prefilledMessage}`
    );
    if (_token) {
      let save_count_body = {
        property_id: user_detail?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };
      dispatch(savePropertyCount(save_count_body, setWhatsAppSuccess));
    } else if (compareDatesWithin24Hours(decryptedData?.c_date)) {
      let save_count_body = {
        property_id: user_detail?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };
      dispatch(savePropertyCount(save_count_body, setWhatsAppSuccess));
    } else {
      setMedia("whatsapp");
      setGuestPopup(true);
    }
  }

  function handleAddViewCount() {
    let save_count_body = {
      property_id: my_params?.id,
      device_id: decryptedData?.device_id,
      type: "view", //contact or view
    };
    // setContactPopup(true);
    dispatch(savePropertyCount(save_count_body, setViewed));
  }

  useEffect(() => {
    // console.log("decryptedData =",decryptedData)
  }, [decryptedData]);

  useEffect(() => {
    if (whatsAppSuccess) {
      setWhatsAppSuccess(false);
      setTimeout(() => {
        window.open(whatsappURL, "_blank");
      }, 1000);
    }
  }, [whatsAppSuccess]);

  useEffect(() => {
    if (guestVerified && media == "call") {
      setGuestVerified(false);
      setMedia("");
      // setContactPopup(true);
      let save_count_body = {
        property_id: propertySingleStore?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };

      dispatch(savePropertyCount(save_count_body, setContactPopup));
    } else if (guestVerified && media == "whatsapp") {
      setGuestVerified(false);
      setMedia("");

      let save_count_body = {
        property_id: propertySingleStore?._id,
        device_id: decryptedData?.device_id,
        type: "contact", //contact or view
      };

      dispatch(savePropertyCount(save_count_body, setWhatsAppSuccess));
      // window.open(whatsappURL, '_blank');
    }
  }, [guestVerified]);

  useEffect(() => {
    handleSingleProperty();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [window.location.pathname]);

  useEffect(() => {
    handleAddViewCount();
    sessionStorage.clear();
    dispatch(clearPropertyData(null));
  }, []);

  useEffect(() => {
    let Con_details = JSON.parse(localStorage.getItem("userToken"));
    if (Con_details) {
      setGetContact({
        ...getContact,
        name: Con_details?.name,
        phone: Con_details?.phone,
        email: Con_details?.email,
      });
    }
  }, []);
  return (
    <Box bgcolor={"#f8f8f8"} pt={3} pb={5}>
      <Container>
        <Box className="d-flex-between" mb={3}>
          <Typography>
            <span
              onClick={() => navigate("/homepage")}
              className={classes.single_breadcrums_active}
            >
              Home
            </span>
            /
            {localStorage.getItem("search-input") && (
              <>
                <span
                  onClick={() => navigate(-1)}
                  className={classes.single_breadcrums_active}
                >
                  Search Results
                </span>
                /
                <span className={classes.single_breadcrums_inactive}>
                  {localStorage.getItem("search-input")}
                </span>
              </>
            )}
            {!localStorage.getItem("search-input") && (
              <>
                <span
                  onClick={() => navigate(-1)}
                  className={classes.single_breadcrums_active}
                >
                  Back to Previous Page
                </span>
                /
                <span className={classes.single_breadcrums_inactive}>
                  {propertyDetails?.modifiedData?.location}
                </span>
              </>
            )}
          </Typography>
          <Typography>
            Last updated:{" "}
            {Convertdate(propertyDetails?.modifiedData?.updatedAt)}
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={"100%"}
            >
              <Box>
                <Typography className={classes.single_details_header}>
                  {propertyDetails?.modifiedData?.offer_type}{" "}
                  {propertyDetails?.modifiedData?.property_type}{" "}
                  {propertyDetails?.modifiedData?.kind_of_property}{" "}
                  {propertyDetails?.modifiedData?.property_status ===
                    "Drafted" && (
                    <span className={classes.draft_list}>Draft</span>
                  )}
                </Typography>
                <span className={classes.location_det}>
                  {propertyDetails?.modifiedData?.location || "n/a"}
                </span>
              </Box>
              <Typography sx={{ color: "#3D6C6D" }}>
                <HistoryIcon />
                {"  "} {propertyDetails?.modifiedData?.owner_contacted} people
                contacted & {propertyDetails?.modifiedData?.view_count} peoples
                viewed
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3}></Grid>
          <Grid item xs={12} sm={12} md={5} mt={{ xs: 3, sm: 3, md: 0 }}>
            <Box className={classes.price_part}>
              <Box>
                <Typography>
                  <b>
                    ₹{" "}
                    {formatNumber(
                      propertyDetails?.modifiedData?.price?.expected_price
                    )}
                  </b>{" "}
                  | ₹ {propertyDetails?.modifiedData?.rate_per_sqft?.toFixed(2)}{" "}
                  Per sq.ft
                </Typography>
                <span>Price (Negotiable)</span>
                <h5>EMI Starts at ₹26.20 K </h5>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"15px"}
                justifyContent={"end"}
                flexWrap={"wrap"}
                mt={2}
              >
                <Button
                  className={classes.contact_btns}
                  // href= {`https://wa.me/+91${propertyDetails?.modifiedData?.created_user?.phone}`}
                  // url="https://web.whatsapp.com/"
                  // target="_blank"
                  onClick={() =>
                    handleWhatsAppClicked(propertyDetails?.modifiedData)
                  }
                >
                  <WhatsappIcon /> WhatsApp
                </Button>
                <Button className={classes.contact_btns}>
                  <ChatIcon /> SMS
                </Button>
                <Button
                  className={classes.contact_btns}
                  // onClick={() => {
                  //   setContactPopupDetails({
                  //     phone: propertyDetails?.modifiedData?.created_user?.phone,
                  //     email: propertyDetails?.modifiedData?.created_user?.email,
                  //     name: propertyDetails?.modifiedData?.created_user?.name,
                  //   });
                  //   setContactPopup(true);
                  // }}
                  onClick={() =>
                    handleCallClicked(propertyDetails?.modifiedData)
                  }
                >
                  <ContactIcon /> Call
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Typography
          mt={2}
          mb={-2}
          sx={{ cursor: "pointer" }}
          onClick={() => setImagePop(true)}
        >
          View All images / videos <MdOutlineKeyboardDoubleArrowRight />
        </Typography>
        {propertyDetails.modifiedData && (
          <Grid container my={2} spacing={2}>
            <Grid item xs={12} sm={12} md={7}>
              <Box
                height={{ xs: "250px", sm: "250px", md: "450px" }}
                position={"relative"}
              >
                <img
                  src={
                    propertyDetails?.modifiedData?.photos[0]?.image
                      ? `${process.env.REACT_APP_API_URL}/${propertyDetails?.modifiedData?.photos[0]?.image}`
                      : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                  }
                  alt="image"
                  width={"100%"}
                  height={"100%"}
                  style={{ borderRadius: "8px" }}
                />
                <Box className={classes.share_part}>
                  <Typography
                    className={
                      propertyDetails?.modifiedData?.likedByUser &&
                      classes.active_heart
                    }
                    onClick={() => handleWishlist("12lop")}
                  >
                    <HeartIcon />
                  </Typography>
                  <Typography onClick={handleShareProperty}>
                    <ShareIcon />
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              {propertyDetails?.modifiedData?.photos.length > 1 && (
                <Box
                  height={"450px"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                >
                  <Box height={"48%"}>
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${propertyDetails?.modifiedData?.photos[1]?.image}`}
                      alt="image"
                      width={"100%"}
                      height={"100%"}
                      style={{ borderRadius: "8px" }}
                    />
                  </Box>
                  {propertyDetails?.modifiedData?.photos.length > 2 && (
                    <Box height={"48%"} position={"relative"}>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${propertyDetails?.modifiedData?.photos[1]?.image}`}
                        alt="image"
                        width={"100%"}
                        height={"100%"}
                        style={{ borderRadius: "8px" }}
                      />
                      <Typography
                        className={classes.click_more}
                        onClick={() => setImagePop(true)}
                      >
                        {/* <img src={More} alt="image" width={"100px"} style={{cursor:"pointer"}} /> */}
                        <Typography className={classes.click_more_count}>
                          + <br />
                          {propertyDetails?.modifiedData?.photos.length -
                            2}{" "}
                          More
                        </Typography>
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        )}
        <Box className={classes.sub_details_wrapper}>
          <Box className={classes.property_sub_details}>
            <Typography>
              {propertyDetails?.modifiedData?.area_of_property} sq.ft
            </Typography>
            <span>Plot Area</span>
          </Box>
          <Typography
            width={"1px"}
            height={"55px"}
            bgcolor={"#1A1A1A"}
            display={{ xs: "none", sm: "none", md: "block" }}
          ></Typography>
          <Box className={classes.property_sub_details}>
            <Typography>
              ₹ {propertyDetails?.modifiedData?.rate_per_sqft?.toFixed(2)} Per
              sq.ft
            </Typography>
            <span>Avg. Price</span>
          </Box>
          <Typography
            width={"1px"}
            height={"55px"}
            bgcolor={"#1A1A1A"}
            display={{ xs: "none", sm: "none", md: "block" }}
          ></Typography>
          <Box className={classes.property_sub_details}>
            <Typography>
              {handleReplace(propertyDetails?.modifiedData?.kind_of_property)}
            </Typography>
            <span>Zone Type</span>
          </Box>
          <Typography
            width={"1px"}
            height={"55px"}
            bgcolor={"#1A1A1A"}
            display={{ xs: "none", sm: "none", md: "block" }}
          ></Typography>
          <Box className={classes.property_sub_details}>
            <Typography>
              {handleReplace(propertyDetails?.modifiedData?.ownership)}
            </Typography>
            <span>Ownership</span>
          </Box>
        </Box>
        <Box className="single-property-tabs">
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="wrapped label tabs example"
          >
            <Tab value="one" label="About" />
            <Tab
              value="two"
              label="Overview"
              onClick={() => {
                // window.scrollTo({
                //   top: 1000,
                // });
                scrollToSection("a-overview");
              }}
            />
            <Tab
              value="three"
              label="Facilities"
              onClick={() => {
                // window.scrollTo({
                //   top: 1500,
                // });
                scrollToSection("a-facilities");
              }}
            />
            <Tab
              value="four"
              label="Amenities"
              onClick={() => {
                scrollToSection("a-amenities");
              }}
            />
          </Tabs>
        </Box>
        <Grid container my={1} spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <Box className={classes.details_part} mb={2}>
              <Typography className={classes.details_header}>
                About The Property
              </Typography>
              <hr />
              <Typography className={classes.details_para}>
                {readAbout
                  ? propertyDetails?.modifiedData?.property_details
                  : propertyDetails?.modifiedData?.property_details
                      ?.slice(0, 200)
                      .concat(" ...")}
              </Typography>
              <hr />
              <Typography
                className={readAbout ? classes.read_less : classes.read_more}
                onClick={() => setReadAbout(!readAbout)}
              >
                {readAbout ? (
                  <span className={readAbout && classes.read_active}>
                    {" "}
                    Less <DownArrowIcon />
                  </span>
                ) : (
                  <span>
                    {" "}
                    Read More <DownArrowIcon />
                  </span>
                )}
              </Typography>
            </Box>
            <Box className={classes.details_part} mb={2} id="a-overview">
              <Typography className={classes.details_header}>
                Overview
              </Typography>
              <hr />
              <Grid container>
                <Grid item xs={6} sm={6} md={6} mb={4}>
                  <Box className={classes.contents}>
                    <span>Plot Area</span>
                    <Typography>
                      {propertyDetails?.modifiedData?.area_of_property} sq.ft
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} mb={4}>
                  <Box className={classes.contents}>
                    <span>Price</span>
                    <Typography>
                      {/* {propertyDetails?.modifiedData?.price?.pricing_details?.includes("Price negotiable") ?
                    "Negotiable":"N/A"  
                    } */}
                      {propertyDetails?.modifiedData?.price?.pricing_details
                        ? handlePriceDetailsList(
                            propertyDetails?.modifiedData?.price
                              ?.pricing_details
                          )
                        : "N/A"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} mb={4}>
                  <Box className={classes.contents}>
                    <span>Ownership</span>
                    <Typography>
                      {handleReplace(propertyDetails?.modifiedData?.ownership)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} mb={4}>
                  <Box className={classes.contents}>
                    <span>Availability</span>
                    <Typography>
                      {propertyDetails?.modifiedData?.availability
                        ? propertyDetails?.modifiedData?.availability
                        : "N/A"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} mb={2}>
                  <Box className={classes.contents}>
                    <span>Boundary Wall</span>
                    <Typography>No</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.details_part} mb={2} id="a-facilities">
              <Typography className={classes.details_header}>
                Facilities
              </Typography>
              <hr />
              <Grid container>
                {propertyDetails?.modifiedData?.other_features.map((el) => (
                  <Grid item xs={6} sm={6} md={6} mb={4}>
                    <Box className={classes.contents}>
                      <span>Included</span>
                      <Typography>{el}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box className={classes.details_part} mb={2} id="a-amenities">
              <Typography className={classes.details_header}>
                Amenities
              </Typography>
              <hr />
              <Grid container>
                {propertyDetails?.modifiedData?.amenities.map((el) => (
                  <Grid item xs={6} sm={6} md={6} mb={4}>
                    <Box className={classes.contents}>
                      <span>Included</span>
                      <Typography>{el}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {user_id != propertyDetails?.modifiedData?.created_user?.id && (
              <Box className={classes.details_part} mb={2}>
                <Typography className={classes.details_header}>
                  Contact the Owner
                </Typography>
                <Box display={"flex"} alignItems={"center"} gap={"8px"} mt={4}>
                  <Typography className={classes.name_avatar}>
                    {splitFirstLetter(
                      propertyDetails?.modifiedData?.created_user?.name
                    )}
                  </Typography>
                  <Box>
                    <Typography className={classes.contact_owner}>
                      {propertyDetails?.modifiedData?.created_user?.name}
                    </Typography>
                    <Typography className={classes.contact_owner}>
                      {" "}
                      {convertPhone(
                        propertyDetails?.modifiedData?.created_user?.phone
                      )}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Typography mt={2}>Please Share Your Contact</Typography>
                <ValidatorForm
                  useref="form"
                  //   onSubmit={handleNext}
                  className={classes.SignUpForm}
                >
                  <TextValidator
                    variant="standard"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Name"
                    className={`${classes.formInputFeild} my-field`}
                    name={"name"}
                    value={inputs.name}
                    size="normal"
                    onChange={handleChange}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    style={{ margin: "8px 0" }}
                    // onKeyPress={(e) => {
                    //   Numeric(e);
                    // }}
                    validators={["required"]} // Add this line
                    errorMessages={["Your name is required"]}
                  />

                  <TextValidator
                    variant="standard"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Phone"
                    className={`${classes.formInputFeild} my-field`}
                    name={"phone"}
                    value={inputs.phone}
                    size="normal"
                    onChange={handleChange}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    style={{ margin: "8px 0" }}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                    errorMessages={[
                      "Mobile Number is required",
                      "Enter 10 digit valid Mobile number",
                    ]}
                  />
                  <TextValidator
                    variant="standard"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Email"
                    className={`${classes.formInputFeild} my-field`}
                    name={"email"}
                    value={inputs.email}
                    size="normal"
                    onChange={handleChange}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    // onKeyPress={(e) => {
                    //   Numeric(e);
                    // }}
                    style={{ margin: "8px 0" }}
                    validators={["required", "isEmail"]} // Add this line
                    errorMessages={[
                      "Email is required",
                      "Enter Valid email ID",
                    ]}
                  />
                  <Box display={"flex"} alignItems={"start"} mt={1}>
                    <Checkbox
                      {...label}
                      onChange={(e) => handleCheckBox(e)}
                      checked={isOwnerChecked}
                      className={classes.checkbox}
                    />
                    <Typography className={classes.checkbox_content}>
                      I agree to be contacted by Housing and other agents
                      via WhatsApp, SMS, phone, email etc
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Button
                      fullWidth
                      className={classes.register_btn}
                      type="submit"
                      onClick={handleContactOwner}
                      disabled={!isOwnerChecked}
                    >
                      Get Contact Details
                    </Button>
                  </Box>
                </ValidatorForm>
              </Box>
            )}
            <Box>
              <img src={AdImg} width={"100%"} />
            </Box>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Typography className={classes.single_details_header}>
            <div className="d-flex-between">
              <Typography sx={{fontSize:"32px"}}>Similar Properties</Typography>
              <Link to="/search-results/allproperties" >
          <p style={{fontSize:"24px"}}>    View All Property</p>
              </Link>
            </div>
            <PropertySlider
              properties={propertyDetails?.suggesstedProperties}
              title="all"
              handleWishlist={handleWishlist}
            />
          </Typography>
        </Box>
      </Container>
      <ImageSlider
        open={imagePop}
        handleClose={handleClose}
        propertyDetails={propertyDetails?.modifiedData}
      />
      {/* <ContactOwnerPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          handleGetContact={handleGetContact}
          getContact={getContact}
        /> */}
      <ContactPop
        open={contactPopup}
        handleClose={handleContactPopClose}
        contactPopupDetail={contactPopupDetail}
        // handleGetContact={handleGetContact}
        // getContact={getContact}
      />
      {loginErr && (
        <AlertBox
          type={"error"}
          message={loginErr}
          stateName={() => setLoginErr("")}
        />
      )}
      {guestPopup && (
        <GuestUserPop
          open={guestPopup}
          handleClose={() => setGuestPopup(false)}
          media={media}
          setGuestVerified={setGuestVerified}
        />
      )}
    </Box>
  );
}

export default SinglePropertyDetails;
